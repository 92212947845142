<template>
    <div>
        <styled-interface>
            <template #interface-toolbar-external>
                <agency-toolbar-global />
            </template>
            <template #interface-heading>
                <h2>Advertising</h2>
            </template>
            <styled-card class="mb-4">
                <template #heading>
                    Meta Advertising
                </template>
                <template #action-buttons>
                    <div class="grey--text">
                        <em>
                            Updated <time-ago :value="now.toDate()" />
                        </em>
                    </div>
                    <action-button
                        icon="refresh"
                        :loading="loading || loadingAdAccounts"
                        :disabled="loading || loadingAdAccounts"
                        @click="loadData">
                        Refresh
                    </action-button>
                    <action-button
                        :disabled="loading || loadingAdAccounts || !hasConfirmedLoad"
                        icon="download"
                        @click="exportCSV">
                        Export CSV
                    </action-button>
                </template>

                <div class="groups-picker">
                    <div class="groups-picker__holder">
                        <groups-picker-global :disabled="loading || loadingAdAccounts" />
                    </div>
                </div>
                <div
                    v-if="loadingAdAccounts || groupsLoading"
                    class="ma-5">
                    <loader label="Loading accounts..." />
                </div>
                <load-confirmation
                    v-else-if="!hasConfirmedLoad && !loadingAdAccounts"
                    report-name="Meta Advertising"
                    data-type="advertising"
                    :dealers-total="accountsTotal"
                    @load="handleLoadConfirmation" />
                <progressbar-timed
                    v-model="loading"
                    class="pa-5"
                    :gain="loadingGain"
                    :top-end="98">
                    {{ progressMessage }}
                </progressbar-timed>
                <div v-show="!loading && hasConfirmedLoad && !loadingAdAccounts">
                    <custom-table
                        v-if="!loading"
                        :items="dealerInsights"
                        :fields="fields"
                        :has-filters="true"
                        :has-totals="true"
                        :has-customization="true"
                        :loading="loading"
                        :header-margin="145"
                        :rows-per-page="50"
                        name="facebook-advertising"
                        min-width="1200px" />
                </div>
            </styled-card>
        </styled-interface>
    </div>
</template>

<script>
import Loader from '@/components/globals/Loader';
import LoadConfirmation from '@/components/globals/LoadConfirmation';
import CustomTable from '@/components/globals/CustomTable/index.vue';
import StyledCard from '@/components/globals/StyledCard';
import StyledInterface from '@/components/globals/StyledInterface';
import ActionButton from '@/components/globals/ActionButton';
import AgencyToolbarGlobal from '@/components/globals/AgencyToolbarGlobal';
import TimeAgo from '@/components/globals/TimeAgo';
import ProgressbarTimed from '@/components/globals/ProgressbarTimed';
import GroupsPickerGlobal from '@/components/globals/GroupsPickerGlobal';
import { mapState } from 'vuex';
import { get } from 'lodash';
import moment from 'moment';
import { ExportToCsv } from 'export-to-csv';
import getAgencyInsights from '@/apis/facebook/getAgencyInsights';
import formatInsightsData from '@/apis/facebook/formatInsightsData';

const attributionWindows = ['1d_view', '1d_click', '7d_view', '7d_click', '28d_view', '28d_click'];

export default {
    name: 'CustomTableDemo',
    title: 'Meta Advertising',
    components: {
        Loader,
        LoadConfirmation,
        CustomTable,
        StyledCard,
        StyledInterface,
        ActionButton,
        GroupsPickerGlobal,
        AgencyToolbarGlobal,
        TimeAgo,
        ProgressbarTimed
    },
    data() {
        return {
            loading: false,
            now: null,
            loadingGain: 0.5,
            accountsTotal: 0,
            loadingAdAccounts: false,
            hasConfirmedLoad: false,
            progressMessage: '',
            fields: [
                {
                    id: 'dealer-id',
                    header: 'Account ID',
                    value: 'dealer.id',
                    width: '80px',
                    align: 'left',
                    sortable: true,
                    filter: '',
                    type: 'single',
                },
                {
                    id: 'dealer',
                    header: 'Account Name',
                    value: 'dealer.name',
                    width: '180px',
                    align: 'left',
                    sortable: true,
                    filter: '',
                    type: 'component',
                    component: () => import('@/components/resellers/facebook-advertising/DealerRoutingLink.vue'),
                },
                {
                    id: 'ad-account-id',
                    header: 'Ad Account ID',
                    value: 'id',
                    width: '150px',
                    align: 'left',
                    sortable: true,
                    filter: '',
                    type: 'single',
                },
                {
                    id: 'ad-account',
                    header: 'Ad Account',
                    value: 'name',
                    width: '280px',
                    align: 'left',
                    sortable: true,
                    filter: '',
                    type: 'component',
                    component: () => import('@/components/resellers/facebook-advertising/AdAccountLink.vue'),
                },
                {
                    id: 'spend',
                    header: 'Spend',
                    value: 'insights.spend.value',
                    width: '80px',
                    align: 'center',
                    sortable: true,
                    filter: '',
                    type: 'component',
                    component: () => import('@/components/resellers/facebook-advertising/FacebookAdvertisingCell.vue'),
                    props: {
                        itemPath: 'insights.spend.formatted',
                        isNullPath: 'insights.spend.value'
                    },
                    total: true,
                    average: true,
                    format: 'currency',
                    tooltip: 'The estimated total amount of money you\'ve spent on your campaign, ad set or ad during its schedule.'
                },
                {
                    id: 'reach',
                    header: 'Reach',
                    value: 'insights.reach.value',
                    width: '80px',
                    align: 'center',
                    sortable: true,
                    filter: '',
                    type: 'component',
                    component: () => import('@/components/resellers/facebook-advertising/FacebookAdvertisingCell.vue'),
                    props: {
                        itemPath: 'insights.reach.formatted',
                        isNullPath: 'insights.reach.value'
                    },
                    total: true,
                    average: true,
                    format: 'number',
                    tooltip: 'The number of people who saw your ads at least once. Reach is different from impressions, which may include multiple views of your ads by the same people.'
                },
                {
                    id: 'frequency',
                    header: 'Freq.',
                    value: 'insights.frequency.value',
                    width: '80px',
                    align: 'center',
                    sortable: true,
                    filter: '',
                    type: 'component',
                    component: () => import('@/components/resellers/facebook-advertising/FacebookAdvertisingCell.vue'),
                    props: {
                        itemPath: 'insights.frequency.formatted',
                        isNullPath: 'insights.frequency.value'
                    },
                    total: true,
                    average: true,
                    format: 'decimal',
                },
                {
                    id: 'impressions',
                    header: 'Impr.',
                    value: 'insights.impressions.value',
                    width: '80px',
                    align: 'center',
                    sortable: true,
                    filter: '',
                    type: 'component',
                    component: () => import('@/components/resellers/facebook-advertising/FacebookAdvertisingCell.vue'),
                    props: {
                        itemPath: 'insights.impressions.formatted',
                        isNullPath: 'insights.impressions.value'
                    },
                    total: true,
                    average: true,
                    format: 'number',
                    tooltip: 'Impressions are a common metric used by the online marketing industry. Impressions measure how often your ads were on screen for your target audience. An impression is counted as the number of times an instance of an ad is on screen for the first time. (Example: If an ad is on screen and someone scrolls down, and then scrolls back up to the same ad, that counts as 1 impression.)'
                },
                {
                    id: 'cpm',
                    header: 'CPM',
                    value: 'insights.cpm.value',
                    width: '80px',
                    align: 'center',
                    sortable: true,
                    filter: '',
                    type: 'component',
                    component: () => import('@/components/resellers/facebook-advertising/FacebookAdvertisingCell.vue'),
                    props: {
                        itemPath: 'insights.cpm.formatted',
                        isNullPath: 'insights.cpm.value'
                    },
                    total: (totals) => {
                        return totals.spend / (totals.impressions / 1000);
                    },
                    format: 'currency',
                    tooltip: 'CPM is a common metric used by the online advertising industry to gauge the cost-effectiveness of an ad campaign. It\'s often used to compare performance among different ad publishers and campaigns. CPM measures the total amount spent on an ad campaign, divided by impressions, multiplied by 1,000. (Example: If you spent $50 and got 10,000 impressions, your CPM was $5.)'
                },
                {
                    id: 'clicks',
                    header: 'Clicks',
                    value: 'insights.clicks.value',
                    width: '80px',
                    align: 'center',
                    sortable: true,
                    filter: '',
                    type: 'component',
                    component: () => import('@/components/resellers/facebook-advertising/FacebookAdvertisingCell.vue'),
                    props: {
                        itemPath: 'insights.clicks.formatted',
                        isNullPath: 'insights.clicks.value'
                    },
                    total: true,
                    average: true,
                    format: 'number',
                    tooltip: 'The number of clicks on your ads. The metric counts multiple types of clicks on your ad, including certain types of interactions with the ad container, links to other destinations, and links to expanded ad experiences.'
                },
                {
                    id: 'ctr',
                    header: 'CTR',
                    value: 'insights.ctr.value',
                    width: '80px',
                    align: 'center',
                    sortable: true,
                    filter: '',
                    type: 'component',
                    component: () => import('@/components/resellers/facebook-advertising/FacebookAdvertisingCell.vue'),
                    props: {
                        itemPath: 'insights.ctr.formatted',
                        isNullPath: 'insights.ctr.value'
                    },
                    total: (totals) => {
                        return totals.clicks / totals.impressions;
                    },
                    format: 'percent',
                    tooltip: 'Click through rate.  This metric is calculated as the total impressions divided by clicks.'
                },
                {
                    id: 'cost_per_click',
                    header: 'CPC',
                    value: 'insights.cost_per_click.value',
                    width: '80px',
                    align: 'center',
                    sortable: true,
                    filter: '',
                    type: 'component',
                    component: () => import('@/components/resellers/facebook-advertising/FacebookAdvertisingCell.vue'),
                    props: {
                        itemPath: 'insights.cost_per_click.formatted',
                        isNullPath: 'insights.cost_per_click.value'
                    },
                    total: (totals) => {
                        return totals.spend / totals.clicks;
                    },
                    format: 'currency',
                    tooltip: 'CPC shows how much, on average, each link click costs you. CPC is a metric used in the online advertising industry for benchmarking ad efficiency and performance. The metric is calculated as the total amount spent divided by link clicks.'
                },
                {
                    id: 'landing_page_views',
                    header: 'LPV',
                    value: 'insights.landing_page_views.value',
                    width: '80px',
                    align: 'center',
                    sortable: true,
                    filter: '',
                    type: 'component',
                    component: () => import('@/components/resellers/facebook-advertising/FacebookAdvertisingCell.vue'),
                    props: {
                        itemPath: 'insights.landing_page_views.formatted',
                        isNullPath: 'insights.landing_page_views.value'
                    },
                    total: true,
                    average: true,
                    format: 'number',
                    tooltip: 'The number of times a person clicked on an ad link and then successfully loaded the destination webpage or Instant Experience.  This metric is in development as stated by Facebook.'
                },
                {
                    id: 'cost_per_landing_page_view',
                    header: 'CPLPV',
                    value: 'insights.cost_per_landing_page_view.value',
                    width: '80px',
                    align: 'center',
                    sortable: true,
                    filter: '',
                    type: 'component',
                    component: () => import('@/components/resellers/facebook-advertising/FacebookAdvertisingCell.vue'),
                    props: {
                        itemPath: 'insights.cost_per_landing_page_view.formatted',
                        isNullPath: 'insights.cost_per_landing_page_view.value'
                    },
                    total: (totals) => {
                        return totals.spend / totals.landing_page_views;
                    },
                    format: 'currency',
                    tooltip: 'Cost per Landing Page view. This metric is calculated as the total amount spend divided by Landing Page views.'
                },
                {
                    id: 'landing_page_view_rate',
                    header: 'LPV / Reach',
                    value: 'insights.landing_page_view_rate.value',
                    width: '80px',
                    align: 'center',
                    sortable: true,
                    filter: '',
                    type: 'component',
                    component: () => import('@/components/resellers/facebook-advertising/FacebookAdvertisingCell.vue'),
                    props: {
                        itemPath: 'insights.landing_page_view_rate.formatted',
                        isNullPath: 'insights.landing_page_view_rate.value'
                    },
                    total: (totals) => {
                        return totals.landing_page_views / totals.reach;
                    },
                    format: 'percent',
                    whitespace: 'normal',
                    tooltip: 'Landing page view rate.  This metric is calculated as the total impressions divided by landing page views.'
                },
                {
                    id: 'website_leads',
                    header: 'Off-FB Leads',
                    value: 'insights.website_leads.value',
                    width: '90px',
                    align: 'center',
                    sortable: true,
                    filter: '',
                    type: 'component',
                    component: () => import('@/components/resellers/facebook-advertising/FacebookAdvertisingCell.vue'),
                    props: {
                        itemPath: 'insights.website_leads.formatted',
                        isNullPath: 'insights.website_leads.value'
                    },
                    total: true,
                    average: true,
                    format: 'number',
                    whitespace: 'normal',
                    tooltip: 'Also known as "Web Leads" these are the total number of combined phone clicks, email clicks, form submissions with contact information and widget conversions on your website that were referred by Facebook ads.  Note that widget conversions represent people who used a chat/trade/finance widget for more than our defined conversion threshold for that widget.'
                },
                {
                    id: 'on_site_leads',
                    header: 'On-FB Leads',
                    value: 'insights.on_site_leads.value',
                    width: '90px',
                    align: 'center',
                    sortable: true,
                    filter: '',
                    type: 'component',
                    component: () => import('@/components/resellers/facebook-advertising/FacebookAdvertisingCell.vue'),
                    props: {
                        itemPath: 'insights.on_site_leads.formatted',
                        isNullPath: 'insights.on_site_leads.value'
                    },
                    total: true,
                    average: true,
                    format: 'number',
                    whitespace: 'normal',
                    tooltip: 'Any leads that occurred on the Facebook property.  These include lead form submissions, messenger inquiries on product pages and other actions specific to various playbooks.'
                },
                {
                    id: 'leads',
                    header: 'Total Leads',
                    value: 'insights.leads.value',
                    width: '90px',
                    align: 'center',
                    sortable: true,
                    filter: '',
                    type: 'component',
                    component: () => import('@/components/resellers/facebook-advertising/FacebookAdvertisingCell.vue'),
                    props: {
                        itemPath: 'insights.leads.formatted',
                        isNullPath: 'insights.leads.value'
                    },
                    total: true,
                    average: true,
                    format: 'number',
                    whitespace: 'normal',
                    tooltip: 'The sum of all Facebook form leads and website leads.'
                },
                {
                    id: 'cost_per_lead',
                    header: 'CPL',
                    value: 'insights.cost_per_lead.value',
                    width: '90px',
                    align: 'center',
                    sortable: true,
                    filter: '',
                    type: 'component',
                    component: () => import('@/components/resellers/facebook-advertising/FacebookAdvertisingCell.vue'),
                    props: {
                        itemPath: 'insights.cost_per_lead.formatted',
                        isNullPath: 'insights.cost_per_lead.value'
                    },
                    total: (totals) => {
                        return totals.spend / totals.leads;
                    },
                    format: 'currency',
                    whitespace: 'normal',
                    tooltip: 'Cost per lead. This metric is calculated as total amount spent divided by leads.'
                },
                {
                    id: 'lead_rate',
                    header: 'Lead / Clicks',
                    value: 'insights.lead_rate.value',
                    width: '90px',
                    align: 'center',
                    sortable: true,
                    filter: '',
                    type: 'component',
                    component: () => import('@/components/resellers/facebook-advertising/FacebookAdvertisingCell.vue'),
                    props: {
                        itemPath: 'insights.lead_rate.formatted',
                        isNullPath: 'insights.lead_rate.value'
                    },
                    total: (totals) => {
                        return totals.leads / totals.clicks;
                    },
                    format: 'percent',
                    whitespace: 'normal',
                    tooltip: 'Lead/Conversion Rate.  The total number of clicks divided by the total leads.'
                },
                {
                    id: 'lead_to_landing_page_view_rate',
                    header: 'Leads / LPV',
                    value: 'insights.lead_to_landing_page_view_rate.value',
                    width: '90px',
                    align: 'center',
                    sortable: true,
                    filter: '',
                    type: 'component',
                    component: () => import('@/components/resellers/facebook-advertising/FacebookAdvertisingCell.vue'),
                    props: {
                        itemPath: 'insights.lead_to_landing_page_view_rate.formatted',
                        isNullPath: 'insights.lead_to_landing_page_view_rate.value'
                    },
                    total: (totals) => {
                        return totals.leads / totals.landing_page_views;
                    },
                    format: 'percent',
                    whitespace: 'normal',
                    tooltip: 'Total leads divided by landing page views.'
                },
                {
                    id: 'lead_to_reach_rate',
                    header: 'Leads / Reach',
                    value: 'insights.lead_to_reach_rate.value',
                    width: '90px',
                    align: 'center',
                    sortable: true,
                    filter: '',
                    type: 'component',
                    component: () => import('@/components/resellers/facebook-advertising/FacebookAdvertisingCell.vue'),
                    props: {
                        itemPath: 'insights.lead_to_reach_rate.formatted',
                        isNullPath: 'insights.lead_to_reach_rate.value'
                    },
                    total: (totals) => {
                        return totals.leads / totals.reach;
                    },
                    format: 'percent',
                    whitespace: 'normal',
                    tooltip: 'Total leads divided by the total reach.'
                },
                {
                    id: 'offline_purchase',
                    header: 'Sales',
                    value: 'insights.offline_purchase.value',
                    width: '80px',
                    align: 'center',
                    sortable: true,
                    filter: '',
                    type: 'component',
                    component: () => import('@/components/resellers/facebook-advertising/FacebookAdvertisingCell.vue'),
                    props: {
                        itemPath: 'insights.offline_purchase.formatted',
                        isNullPath: 'insights.offline_purchase.value'
                    },
                    total: true,
                    average: true,
                    format: 'number',
                    tooltip: 'The number of vehicle sales that Facebook has attributed to Offline Events that have been uploaded during the given date range and the selected view/click attribution windows.'
                },
                {
                    id: 'cost_per_offline_purchase',
                    header: 'CPS',
                    value: 'insights.cost_per_offline_purchase.value',
                    width: '80px',
                    align: 'center',
                    sortable: true,
                    filter: '',
                    type: 'component',
                    component: () => import('@/components/resellers/facebook-advertising/FacebookAdvertisingCell.vue'),
                    props: {
                        itemPath: 'insights.cost_per_offline_purchase.formatted',
                        isNullPath: 'insights.cost_per_offline_purchase.value'
                    },
                    total: (totals) => {
                        return totals.spend / totals.sales;
                    },
                    format: 'currency',
                    tooltip: 'Cost per sale. This metric is calculated as total amount spent divided by number of sales.'
                }
            ],
            dealers: [],
            adAccounts: [],
            insightsRaw: [],
        };
    },
    computed: {
        ...mapState({
            agency: (state) => state.agency.currentAgency,
            dateRange: (state) => state.metrics.dateRange,
            selectedGroups: (state) => state.agency.selectedGroups,
            groupsLoading: (state) => state.agency.groupsLoading,
            attribution: (state) => state.metrics.facebook.ads.attribution
        }),
        dealerInsights() {
            return this.insightsRaw.map(account => {
                const newAccount = { ...account };
                const dealer = this.dealers.find(dealer => {
                    return dealer.id === account.dealer_id;
                });

                newAccount.insights = formatInsightsData(account.insights, {
                    attribution: this.attribution,
                    attributionWindows,
                });
                newAccount.dealer = dealer;
                return newAccount;
            });
        },
        selectedGroupsIds() {
            const ids = this.selectedGroups.map(group => { return group?.id });
            if (ids.length) {
                return ids.join();
            } else {
                return null;
            }
        }
    },
    watch: {
        dateRange() {
            this.loadData();
        },
        selectedGroups: {
            handler() {
                this.loadData();
            },
            deep: true,
        },
    },
    created() {
        this.now = moment();
    },
    mounted() {
        this.setTitle();
        this.loadData();
    },
    methods: {
        async handleLoadConfirmation() {
            this.hasConfirmedLoad = true;
            this.loading = true;
            const count = this.adAccounts.length;
            this.loadingGain = 0.5 - (count * 0.0002);
            this.progressMessage = 'Loading Meta insights...';
            await this.getAdAccountInsights();
            this.progressMessage = 'Loading operation complete!';
            this.loading = false;
        },
        setTitle() {
            this.$title = `Meta Advertising - ${this.agency.name}`;
        },
        async loadData() {
            this.hasConfirmedLoad = false;
            this.loadingAdAccounts = true;
            this.now = moment();
            this.progressMessage = 'Loading ad accounts...';
            await this.getDealerAdAccounts();
            if (this.selectedGroupsIds) {
                const selectedGroupsIdsNums = this.selectedGroupsIds.split(',');
                const response = await this.$apiRepository.getAccountGroupsQuery(null, null, null, this.agency.id, selectedGroupsIdsNums);
                const groups = response.data.data;
                const uniqueDealers = new Set();
                groups.forEach(group => {
                    group.dealers.data.forEach(dealer => {
                        uniqueDealers.add(dealer.id);
                    });
                });
                this.accountsTotal = uniqueDealers.size;
            } else {
                this.accountsTotal = this.adAccounts.length;
            }
            this.loadingAdAccounts = false;
        },
        async getDealerAdAccounts() {
            try {
                const response = await this.$http.get('/dealers', {
                    with_relationships: 'dealers.facebook_ad_account_annotations',
                    with_scopes: {
                        'dealers.agency_equals': {
                            id: this.agency.id
                        }
                    },
                    page_size: 1000
                });

                this.dealers = response.data.data;

                const adAccounts = [];

                this.dealers.forEach(dealer => {
                    const adAccount = get(dealer, 'facebook_ad_account_annotations.data[0].facebook_ad_account_id', null);
                    if (adAccount) {
                        adAccounts.push(adAccount);
                    }
                });

                this.adAccounts = adAccounts;
            } catch (error) {
                console.log('Error retreiving dealer ad accounts', error);
            }
        },
        async getAdAccountInsights() {
            if (!this.adAccounts.length) {
                return;
            }
            try {
                this.insightsRaw = await getAgencyInsights({
                    groupsIds: this.selectedGroupsIds ? this.selectedGroupsIds : '',
                    accountIds: this.adAccounts,
                    agencyId: this.agency.id,
                    startDate: moment(this.dateRange.startDate).format('YYYY-MM-DD'),
                    endDate: moment(this.dateRange.endDate).format('YYYY-MM-DD'),
                    campaignFiltering: [{
                        field: 'effective_status',
                        operator: 'IN',
                        value: ['ACTIVE']
                    }],
                    attributionWindows,
                    limit: 150
                });

            } catch (error) {
                console.error('Error retrieving dealer insights', error);
            }
        },
        exportCSV() {
            const options = {
                fieldSeparator: ',',
                quoteStrings: '"',
                decimalSeparator: '.',
                showLabels: true,
                showTitle: false,
                useTextFile: false,
                useBom: true,
                useKeysAsHeaders: true,
                filename: `facebook_advertising_${new Date().getTime()}`
            };
            const csvExporter = new ExportToCsv(options);
            const keys = this.fields
                .map(header => ({
                    text: header.header,
                    id: header.id
                }))
                .splice(4);

            const csvFile = this.dealerInsights.map(dealer => {
                const row = {
                    ['Account ID']: dealer.dealer_id,
                    ['Account Name']: dealer.dealer_name,
                    ['Ad Account ID']: dealer.id,
                    ['Ad Account Name']: dealer.name
                };

                keys.forEach(key => {
                    row[key.text] = dealer.insights[key.id] ? dealer.insights[key.id].formatted : '';
                });

                return row;
            });

            csvExporter.generateCsv(csvFile);
        }
    },
};
</script>
<style lang="scss" scoped>
.groups-picker {
    border-bottom: 1px solid $gray-light;
    padding: 15px 30px;

    &__holder {
        max-width: 255px;
    }
}
</style>
